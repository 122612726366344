@font-face {
    font-family: "acuraBespoke";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("./fonts/acura_bespoke/Acura-Bespoke-Regular.otf") format("opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "acuraBespoke";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url("./fonts/acura_bespoke/Acura-Bespoke-Bold.otf") format("opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "acuraBespoke";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("./fonts/acura_bespoke/Acura-Bespoke-Book.otf") format("opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "acuraBespoke";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("./fonts/acura_bespoke/Acura-Bespoke-Light.otf") format("opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "FuturaNowAcura";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("./fonts/futura_now_for_acura/FuturaNowForAcura-CondXBlk.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "FuturaNowAcura";
    font-style: normal;
    font-weight: 950;
    font-style: italic;
    font-display: swap;
    src: url("./fonts/futura_now_for_acura/FuturaNowForAcuraCnXBlk-It.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}